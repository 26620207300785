@import url('https://fonts.googleapis.com/css2?family=Acme&family=Holtwood+One+SC&display=swap');

body {
  margin: 0;
  font-family: 'Acme', 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#hand {
	transform-box: fill-box;
	transform-origin: center bottom;
	transform: rotate(0deg);
	transition: transform 0.7s cubic-bezier(0.34, 1.56, 0.64, 1);
}
svg text {
	font-family: 'Passport Mono' ! important;
}
.round {
	font-size: 36pt;
	font-family: 'Holtwood One SC', sans-serif;
	text-align: center;
}
.grid-ctr {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.grid-lhs {
	grid-column: 1/2;
	grid-row: 1;
}
.grid-rhs {
	grid-column: 2/2;
	grid-row: 1;
}
.clock {
	text-align: center;
}
.participant {
	font-size: 2em;
	width: 90%;
	margin: 0 auto 0.5em auto;
	border: 8px solid #ccc;
	border-radius: 10px;
	padding: 10px;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

}
.participant.Safe {
	border: 8px solid rgb(6 95 70);
	background-color: rgb(236 253 245);
	border-radius: 10px;
}
.participant.AtRisk.critical {
	border: 8px solid rgb(153 27 27);
	background-color: rgb(254 242 242);
	transform: scale(0.2);
	border-radius: 10px;
	transition: transform 4.0s cubic-bezier(0.7, 0, 0.84, 0);
}
.participant.AtRisk {
	border: 8px solid rgb(252 165 165);
	background-color: rgb(254 242 242);

	transition: transform 0.2s cubic-bezier(0.12, 0, 0.39, 0);;
}
.participant .participant-name {
	grid-column: 1;
}
.participant .participant-state {
	grid-column: 2;
	font-size: 0.6em;
}
.participant .participant-missed {
	grid-column: 3;
	font-size: 0.6em;
}
.controls {
	width: 50%;
	margin: 18px auto;
	text-align: center;
	font-size: 1.3em;
}
.controls button, .controls input {
	background-color: rgb(254 243 199);
	border: 3px solid rgb(146 64 14);
	border-radius: 10px;
	font-family: 'Acme', 'Source Sans Pro', sans-serif;
	font-size: 1.3em;
}
.join {
	font-size: 1.5em;
}
.join span.code {
	font-weight: bold;
	font-family: monospace;
	font-size: 1.3em;
}
.join span.ul {
	text-decoration: underline;
}
.join-wrapper {
	padding: 1vh;
	height: 98vh;
	width: 95%;
	font-size: 1.6em;
	max-width: 600px;
	margin: 0 auto;
	border-left: 6px solid #fff;
	border-right: 6px solid #fff;
}
.join-wrapper input {
	display: block;
	width: 50%;
	margin: 30px auto;
	text-align: center;
}
.join-wrapper input[type='submit'] {
	font-size: 1.3em;
	padding: 5px;
	background-color: rgb(254 243 199);
	border: 3px solid rgb(146 64 14);
	border-radius: 10px;
	font-family: 'Acme', 'Source Sans Pro', sans-serif;
}
.join-wrapper input[type='text'] {
	font-size: 3em;
	font-family: 'Acme', 'Source Sans Pro', sans-serif;
}
.join-wrapper .error {
	text-align: left;
	padding-left: 8px;
	border-left: 8px solid rgb(220 38 38);
	width: 50%;
}
.join-wrapper .boxed {
	border: 2px dotted #ccc;
}
.join-wrapper h1 {
	font-family: 'Holtwood One SC', sans-serif;
	text-align: center;
}
.join-wrapper.unsafe {
	background-color: rgb(254 226 226);
	border-left: 6px solid #f00;
	border-right: 6px solid #f00;
	border-image: repeating-linear-gradient(to top right, red, red 5px, yellow 5px, yellow 10px) 3;
}
.join-wrapper.safe {
	background-color: rgb(209 250 229);
	border-left: 6px solid rgb(20 83 45);
	border-right: 6px solid rgb(20 83 45);
}
